import Hero from "../../common/Hero/Hero"

const ValueProposition = () => {
  return (
    <div className="proposition">
        <Hero img="assets/images/banners/tractor.png" heading="Value Proposition for our Stakeholders" unWidth="10"/>
        <div className="sec-one">
            <div>
                <img src="assets\images\fertilizer\Pic20.png" alt="" />
            </div>
            <div>
                <h3 style={{color:"var(--color-primary-dark)"}}>Dealer Network</h3>
                <p style={{color:'#505050'}}>
                    Access to customers better understanding of their behaviour, Map exact
                    demand in a cluster accordingly get prepared with stock. Advisory linked services will also help
                    dealers to sale/position other products like Seeds, Speciality Nutrients and Pesticides. Direct impact
                    on sales growth through advisory services by FS team in the catchment. Advertisement of their
                    Stores Products- through FS Platform.</p>
            </div>
        </div>
        <div className="sec-two">
            <div>
                <h3 style={{color:"var(--color-orange-dark)"}}>Govt and regulatory Bodies:</h3>
                <p style={{color:'#505050'}}>Govt and regulatory Bodies: Govt schemes/Extension programs sharing with farmers for greater
                    awareness about various welfare schemes. The introduction of FS serves the mandate of increasing
                    farm productivity and farm production in the specific geographies. Crop estimates, crop production,
                    crop losses, and pesticide and fertilizer usage are all evaluated using our data science. FS impacts the
                    livelihoods of farmers by creating awareness within the farming community about new technology
                    and its best usage through FS.
                    </p>
            </div>
            <div>
                <img src="assets\images\fertilizer\Pic19.png" alt="" />
            </div>
        </div>
        <div className="sec-three">
            <div>
                <img src="assets\images\fertilizer\Pic21.png" alt="" />
            </div>
            <div>
                <h3 style={{color:"var(--color-primary-dark)"}}>FPOs/Co-ops:</h3>
                <p style={{color:'var(--color-primary-dark)'}}>FPOs/Co-ops: 
                    Small farms are disadvantaged when it comes to accessing markets, credit, and
                    agricultural inputs such as seeds. By joining together in FPOs, farmers work to jointly reduce costs
                    and improve market access, helping to drive higher agricultural productivity, enhanced food security,
                    and livelihood development. As Agri advisory Services is very critical to farm productivity
                    improvement so for FPos/Co-ops are in a win-win for them to </p>
                <div>
                    <div>
                        <div  style={{backgroundColor:'var(--color-primary-dark)'}}/>
                        <p style={{color:'var(--color-primary-dark)',fontSize:'1rem', fontWeight:'500'}}>Access to Agri Advisory</p>
                    </div>
                    <div>
                        <div  style={{backgroundColor:'var(--color-orange-dark)'}}/>
                        <p style={{color:'var(--color-orange-dark)',fontSize:'1rem', fontWeight:'500'}}>Access to Agri Inputs</p>
                    </div>
                    <div>
                        <div  style={{backgroundColor:'var(--color-primary-dark)'}}/>
                        <p style={{color:'var(--color-primary-dark)',fontSize:'1rem', fontWeight:'500'}}>Access to Market Linkage</p>
                    </div>
                </div>
                <p style={{color:'var(--color-primary-dark)'}}>Which in turn will be seen as a value addition by FPO for its own shareholders and can-do business
                    with our Nutrisource inputs (Additionally our Market Information through FS helping them to plan
                    output procurement sales).</p>
            </div>
        </div>
        <div className="sec-two">
            <div>
                <h3 style={{color:"var(--color-orange-dark)"}}>Solution for Agri Input Distribution Channel:</h3>
                <p style={{color:'#505050'}}>Our API based data is very dynamic, which supports for Agri input stakeholders & benefits in terms of inventory management, sowing & harvesting date & minimizing risk.</p>
                        <ul>
                            <li>Agri Input demands</li>
                            <li>Advertisement within his catchment area</li>
                            <li>Data security and privacy-related info</li>
                            <li>Crop rotation technique</li>
                            <li>Region-wise farm soil condition</li>
                            <li>Sowing and harvesting dates for different crops </li>
                            <li>Insect, disease, and drought data</li>
                        </ul>
            </div>
            <div>
                <img src="assets\images\fertilizer\image16.png" alt="" />
            </div>
        </div>
    </div>
  )
}

export default ValueProposition