import React, { useEffect } from 'react'

const DeliveryPolicy = () => {

    useEffect(() => {
        window.scroll(0,0)
    },[])

  return (
    <div className='home-bg'>
        <div className="container">
                <div>
                    <h2>Shipping & Delivery Policy</h2>
                </div>
                <section>
                   <p>1. To ensure that your order reaches you in our standard time (6 to 12 working days) and in good condition, we will ship through standard courier agencies like Delhivery, FedEx, Xpressbees, Gati, Dotzot, Ecom Express and many more. </p>
                   <p>Note: If we will ship through postal service then it may take more time.</p>
                   <h3>Transport or bulk order takes 48 to 72 hrs to be dispatched.</h3>
                   <p>2. If you are a new user then, our executive will contact you and confirm your order (For perfect delivery our executive will confirm you your exact delivery address and pin code). If your Pincode is not serviceable or we are not able to send the material then, you have to give us another address and pin code.</p>
                   <p>3. If you believe that the product is not in good condition, or if the packaging is tampered with or damaged before accepting delivery of the goods, please refuse to take delivery of the package, click some pictures of the package and write instruction to courier boy (The package is tampered or damaged in courier so, I will not accept the order) and send us a mail at contact@farmsanta.com mentioning your order reference number and attached pictures or call our Customer Care. We shall make our best efforts to ensure that a replacement delivery is made to you at the earliest.</p>
                   <p>4. Delivery time mentioned on the product, cart page or website is estimated. Actual delivery time is based on the availability of a product, weather condition and address where the product is to be delivered and courier company's rules. </p>
                   <p>5. We try our best to get your product delivered, you have purchased the product from our platform to be delivered to you, we will try our best to deliver, our courier partner will try their best to deliver, but for some reason, if the product may not be delivered or it is late, and due to that if any loss occurs to you note that FarmSanta is not responsible.</p>
                   <p>Note: If your address is ODA location then, you have to self collect the parcel from the courier office. We will provide a courier office address and number. Also, our customer care executive will keep in touch with you.</p>
                   <p>6. For any issues in utilizing our services you may contact us.</p>
                </section>
              
               
                <section>
                    <h4>Contact us</h4>
                    <p>If you have any questions about this Privacy Policy, the practices of this Sites and Mobile Applications, or your dealings with this Sites and Mobile Applications, please contact us at:</p>
                    <div>
                        <h3 >CORPORATE ADDRESS</h3>
                        <div >
                            <h4 >Head Office (Singapore)</h4>
                            <p >NutriSource Pte Ltd 10, Anson Road 22-11 International Plaza Singapore 079903</p>
                        </div>
                        <div >
                            <h4 >Regional Office (Dubai)</h4>
                            <p >NutriSource DMCC 1206, Preatoni Tower, Cluster L, Jumeirah Lake Towers, Dubai, United Arab Emirates</p>
                        </div>
                        <div >
                            <h4 >Regional Office (India)</h4>
                            <p >NutriSource India Private Limited 1st Floor, FL-2, 1/37/6, Bijoygarh Jadavpur LP-55/3/2/5, Kolkata, West Bengal, India 700032</p>
                        </div>
                    </div>
                    <div>
                        <h3 >OFFICES IN AFRICA</h3>
                        <div >
                            <h4 >(Kenya)</h4>
                            <p >NutriSource Kenya Limited P.O. Box 39049-00623, 7th Floor, Victoria Plaza, Parkland Road, Nairobi, Kenya</p>
                        </div>
                        <div >
                            <h4 >(Cameroon)</h4>
                            <p >NutriSource Cameroon Sarl BP 4897, Rue Michelle Brunet Bali Doula Cameroon</p>
                        </div>
                        <div >
                            <h4 >(Togo)</h4>
                            <p >NutriSource Togo Sarl Plot No w2, Plateforme Industrielle d’ Adetikope Parc Industriel d’ Adetikope, Rue NAT No.01 Tsevie Lome, Togo</p>
                        </div>
                    </div>
                    <div>
                        <p>Write to us at <a href="mailto:hey@nutrisourceworld.com">hey@nutrisourceworld.com</a></p>
                    </div>
                </section>
                <section>
                    <p>This document was last updated on : <span>20-Oct-2022</span></p>
                </section>
                <footer >
                    <a href="https://farmsanta.com" target="_blank" >Farmsanta</a>
                    <p>Nutrisource Pte Ltd. &copy; All rights are reserved 2022</p>
                </footer>
        </div>
    </div>
  )
}

export default DeliveryPolicy