import React from 'react'
import Hero from '../../common/Hero/Hero'

const About = () => {
  return (
    <div>
        <Hero img="assets/images/banners/Rectangle_161.png" heading="Farmsanta The Smart Farming tool" unWidth="6"/>
        <div className="aboutUs-container">
            <div className="details">
                {/* <h3 className='sub-heading-main'>Farmsanta The Smart Farming tool</h3> */}
                <h3 className='sub-heading-main'>How does our technology relate to this</h3>
                <p><b>Smart farming</b> is known as different use of data collected by different types of technologies used in the agriculture sector. However, it is also about how this technology integrates and works across the supply chain, all the way from the farm to the consumer.</p>
                <p><b>Smart Agriculture</b> can help with the day-to-day management of farms and improve traceability, security, and automation with real time live data. It includes monitoring animals, prescriptive fertiliser programs and geospatial mapping.</p>
                <p><span>FarmSanta</span> has made it possible to provide real time <b>Agri advisory</b>, <b>crop plan</b>, <b>soil test</b> report and its AI based pest and disease management.  </p>
                <p>The <span>FarmSanta</span> App guides smallholder farmers on the optimal time to take preventive action and protect their farm against avoidable crop losses due to pests, diseases, and nutritional deficiency. </p>
                <p><span>FarmSanta</span> is an AI-powered crop doctor system available through an app on Android smartphones. It helps farmers protect their crops by determining the advice solutions to spray agrochemicals through immediate and localized advice.</p>
                <p>This enables farmers and Agri advisers to take decision on good agricultural practices & reduce crop loss as well optimize their fertilization application strategy. The <b>mobile apps</b> that Scouting feature works as crop doctor which diagnose pest infestation or nutritional deficiency and provide real time best possible solution along with precautionary measure for the same.  This means the <span>farmSanta</span> is a smart farming tool that creates actionable intelligence from data. Farmers get access to <b>STR based fertilizer calculator</b> across devices that give them the opportunity to make informed decisions about fertilizer application. </p>
            </div>
            <div className="how-we-help-sec">
        <div className="underline-head orange small">
            <h3>How We Help ?</h3>
            <p>FarmSanta app is for farmers to make farming more convenient and easy get quick solution of all the plant related problems . </p>
            <div />
        </div>
        <div className="container">
            <img src="assets\images\SVG_Assets\Graphic.png" alt="" />
        </div>
    </div>
            <div className="nutri-source-section" style={{backgroundImage:`url(${"assets/images/banners/Rectangle_499.png"})`,position:"relative", objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <div className="container"  style={{backgroundImage:`url(${"assets/images/banners/web_banners/group_1639.png"})`,position:"relative", objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                <div>
                    <img src="assets/images/santa.png" alt="" />
                </div>
                <div>
                <source srcSet="assets/images/fsLogo-2.png" media="(max-width:765px)" />
                    <img src="assets/images/FarmSanta.png" alt="" />
                    <h2 data-text="Digital Agri Advisor - Anytime , Anywhere">Digital Agriculture Expert Anytime, Anywhere</h2> 
                       
                </div>
            </div>
        </div>
            {/* <div className="santa-section">
                <img src="assets\images\Icons_PNG\santa2.png" alt="" />
                <button><a href='https://play.google.com/store/apps/details?id=com.farmsanta.farmer' target='_blank'><img src="assets\images\farmSantaLogo.png" alt="" /> Download FarmSanta</a></button>
            </div> */}
        </div>
    </div>
  )
}

export default About