import ReactJotformEmbed from "react-jotform-embed"

const ContactUs = () => {
  return (
    <div className="contact-us-main">
        <div className="underline-head dark">
            <h3>Contact Us</h3>
            <div />
        </div>
        <div className="contact-us-container">
            <div>
                <div>
                    <h2>Contact</h2>
                    <p>Email : <a href="contact@farmsanta.com">contact@farmsanta.com</a></p>
                    <p>Support Number</p>
                    <p>India - <a href="tel:+913348126531">+91-33-4812-6531</a></p>
                    <p>Singapore - <a href="tel:+6562232192">+65-6223-2192</a></p>
                    <p>Cameroon - <a href="tel:+237650499999">+237 650499999</a></p>
                    {/* <p></p> */}
                    {/* <p></p> */}
                    <h2 >Office Address (India)</h2>
                            <p >FarmSanta Private Limited</p>
<p>Merlin Matrix</p>
<p>Unit no.304, 3rd Floor,</p>
<p>Sector 5, DN Block, Salt Lake</p>
<p>Kolkata 700 091</p>
<div>
<iframe style={{marginTop:'2rem'}} width="350" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=350&amp;height=500&amp;hl=en&amp;q=Merlin%20Matrix,%20%20Kolkata+(Farmsanta%20Pvt.%20Ltd.)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=95c82aff7c3d07da2657d3b33f8e0d5247c28dba'></script>
</div>
                </div>
                <div>
                    
                       
                    </div>
            </div>
            <div>
                <ReactJotformEmbed src="https://form.jotform.com/211840626162450"/>
            </div>
        </div>
    </div>
  )
}

export default ContactUs