import React from 'react'

const Hero = ({ img, heading, subHeading, large, unWidth }) => {
  return (
    <div id={`${large? 'largeHero' : ''}`} className={`${subHeading ? 'main-hero-sub' : 'main-hero'}`} style={{backgroundImage:`url(${img})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
        <h2>{ heading }</h2>
        <div style={{width:`${unWidth}rem`}}/>
        <p>{ subHeading }</p>
    </div>
  )
}

export default Hero