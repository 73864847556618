import React from 'react'
import Hero from '../../common/Hero/Hero'

const TeamDetails = () => {
  return (
    <div className='team-detail-main'>
        <Hero img={"assets/images/banners/Rectangle_505.png"} large={true}/>
        <div className="team-chart">
            <div className="chart-layout">
                <img src="assets/images/SVG_Assets/Group_1663.svg" alt="" />
                <img src="assets/images/agro_experts/agro1.png" alt="" id='img1'/>
                <img src="assets/images/agro_experts/agro2.png" alt="" id='img2'/>
                <img src="assets/images/agro_experts/agro3.png" alt="" id='img3'/>
                <img src="assets/images/agro_experts/agro4.png" alt="" id='img4'/>
                <img src="assets/images/agro_experts/agro5.png" alt="" id='img5'/>
            </div>
        </div>
        <div className="top-agro">
            <div className="underline-head light">
                  <h3>Our Team</h3>
                  <div />
            </div>
            <p className="under-sub-heading">Get Best Advice for better growth of your crops and Advice to protect your Crops From various issues </p>
            <div className="top-agro-container">
                <div>
                    <img src="assets/images/agro_experts/agro7.png" alt="" />
                    <p>Jenny</p>
                </div>
                <div>
                    <img src="assets/images/agro_experts/agro1.png" alt="" />
                    <p>David</p>
                </div>
                <div>
                    <img src="assets/images/agro_experts/agro2.png" alt="" />
                    <p>Harry</p>
                </div>
                <div>
                    <img src="assets/images/agro_experts/agro3.png" alt="" />
                    <p>David</p>
                </div>
                <div>
                    <img src="assets/images/agro_experts/agro4.png" alt="" />
                    <p>Jordon</p>
                </div>
                <div>
                    <img src="assets/images/agro_experts/agro5.png" alt="" />
                    <p>Jenny</p>
                </div>
                <div>
                    <img src="assets/images/agro_experts/agro6.png" alt="" />
                    <p>Jenny</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TeamDetails