import React from 'react'
import Hero from '../../common/Hero/Hero'

const Product = () => {
    const queryParameters = new URLSearchParams(window.location.search)
  const storeid = queryParameters.get("storeid")
  const productid = queryParameters.get("productid")
  const timeout = setTimeout(() => {
    // 👇️ redirects to an external URL
    window.location.replace('https://play.google.com/store/apps/details?id=com.farmsanta.farmer');
  }, 0);
  return () => clearTimeout(timeout);
  /*return (
    <div>
      <p>Store ID: {storeid}</p>
      <p>Product ID: {productid}</p>
    </div>
  )*/
}

export default Product