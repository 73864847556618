import Slider from 'react-slick'
import Hero from '../../common/Hero/Hero'
import { fertiSlider } from '../Home/Style'

const Fertilizers = () => {
  const investorsSlidetData = [
    { id:1, img:"assets/images/menimage.jpg" , name:"Nitrogen"},
    { id:1, img:"assets/images/menimage.jpg" , name:"Phosphate"},
    { id:1, img:"assets/images/menimage.jpg" , name:"Potassium"},
    { id:1, img:"assets/images/menimage.jpg" , name:"Sulphur"},
    { id:2, img:"assets/images/menimage.jpg" , name:"Calcium (Ca) "},
    { id:2, img:"assets/images/menimage.jpg" , name:"Sulfur (S) "},
    { id:2, img:"assets/images/menimage.jpg" , name:"Iron (Fe) "},
    { id:2, img:"assets/images/menimage.jpg" , name:"CZinc (Zn) "},
    { id:3, img:"assets/images/menimage.jpg" , name:"Manganese (Mn)"},
    { id:3, img:"assets/images/menimage.jpg" , name:"Boron (B)"},
    { id:3, img:"assets/images/menimage.jpg" , name:"Copper (Cu)"},
    { id:3, img:"assets/images/menimage.jpg" , name:"Molybdenum (Mo)"},
    { id:4, img:"assets/images/menimage.jpg" , name:"Chlorine (Cl)"},
    { id:4, img:"assets/images/menimage.jpg" , name:"Sulphur"},
    { id:4, img:"assets/images/menimage.jpg" , name:"Nitrogen"},
    { id:4, img:"assets/images/menimage.jpg" , name:"Phosphate"},
  ]
  const investorsSliderCard = investorsSlidetData.map( dt =>  <div key={dt.id}>
      <div className="fertiSlider">
          <div>
              <div className='img-count'>
                  <div>
                    <div></div>
                    <span>{dt.name}</span>
                  </div>
              </div>
          </div>
      </div>
  </div>)

  return (
    <div className='main-fertilizer'>
        <Hero heading="Fertilizers" img="assets/images/banners/fertilizers.png" unWidth="6"/>
        <div className="sec-one">
            <div>
                <img src="assets/images/card/fertiOne.png" alt="" />
                <div>
                    <h2>Why do we need fertilizers?</h2>
                    <p>Fertilizers are food for plants. Just like as human we need an adequate and consistent diet to grow and stay healthy, same as plants and crops must get proper nutrition from the soil to thrive. Plants require a balanced supply of nitrogen, phosphorus, potassium, and sulphur. As plants extract these nutrients from the soil during every growing season, they must be replenished through fertilizers, manure, and compost added to fields annually. 
                      he advantage of using fertilizers is they can be applied with more control to match crop needs and protect the environment. They can also supplement manure or compost to ensure the soil gets an adequate supply of nutrients. Plants require 17 essential nutrients in the soil to survive and to grow. Fertilizers help replenish these nutrients after each harvest.</p>
                </div>
            </div>
        </div>
        <div className="sec-two">
            <p>Four most important nutrients for
              high crop yields and sustainable food production:</p>
            <Slider {...fertiSlider}> {investorsSliderCard} </Slider>
        </div>
        <div className="sec-three">
            <div className="underline-head dark">
                <h3>Function of the 14 nutrients obtains from the soil and added fertilizers</h3>
                <div style={{width:'10rem'}}/>
            </div>
            <div>
                <div>
                  <h4>Primary Nutrients:</h4>
                  <p>Nitrogen, Phosphorus, and potassium are primary nutrients that plants rapidly utilize, and unfertilized soils normally cannot provide them in quantities needed for best plant growth.</p>
                  <p><span style={{color:'var(--color-primary-dark)', fontWeight:'600'}}>Nitrogen (N) : </span> It’s An important nutrient in the formation and function of chlorophyll – the key component that provides the dark green colour. Promotes rapid vegetative growth (leaf and stem) synthesizing amino acids which in turn form proteins. nitrogen regulates the absorption of other nutrients. </p>
                  <p><span style={{color:'var(--color-orange-dark)', fontWeight:'600'}}>Phosphorus (P) : </span> It stimulates early root formation and development ​​gets plants off to a good start and creates a root filter system in the soil to efficiently take up other available nutrients and water thereby improving plant strength and stamina at the same time it accelerates maturity and stimulates bloom and seed development</p>
                  <p><span style={{color:'var(--color-primary-light)', fontWeight:'600'}}>Potassium (K) : </span> It aids in the growth of stems and leaves as well as increases disease resistance, hardness, strengthens cell walls. Acts as a catalyst in iron uptake. it’s very essential to the formation and translocation of protein, starches, sugar, and oil–improving the size and quality of fruit, grains, and tubers.</p>
                  <p></p>
                </div>
                <div>
                  <img src="assets/images/card/grass.png" alt="" />
                </div>
            </div>
        </div>
        <div className="sec-four">
            <div>
                <div>
                    <img src="assets\images\fertilizer\image15.png" alt="" />
                </div>
                <div>
                    <div>
                      <img src="assets\images\fertilizer\Pic26.png" alt="" />
                    </div>
                    <div>
                        <img src="assets\images\fertilizer\Pic28.png" alt="" />
                        <img src="assets\images\fertilizer\Pic29.png" alt="" />
                        <img src="assets\images\fertilizer\Pic30.png" alt="" />
                    </div>
                </div>
                <div>
                    <img src="assets\images\fertilizer\image16.png" alt="" />
                </div>
                <div>
                      <div>
                          <img src="assets\images\fertilizer\Pic31.png" alt="" />
                      </div>
                      <div>
                          <img src="assets\images\fertilizer\Pic27.png" alt="" />
                      </div>
                </div>
            </div>
            <div>
                <h4>Secondary Nutrients:</h4>
                <p>They are used in somewhat less quantities than the primary Nutrient, but they are just as essential for plant growth and quality. </p>
                <p><span>Calcium (Ca) : </span> Calcium is an essential part of cell wall structure and must be present for the formation of new cells. Deficiency of calcium causes weakened stems and premature shedding of blossoms and buds</p>
                <p><span>Magnesium (Mg) : </span> Essential for photosynthesis (greening of plant) and its works as activator for many plant enzymes required in growth process. </p>
                <p><span>Sulfur (S) : </span> A constituent of three amino acids and is therefore essential in the formation of protein.</p>
                <p><span>C. Micronutrients : </span> Even though micronutrients are used by plants in very small amounts, they are just as essential for plant growth as large amounts of primary and secondary nutrients. They must be maintained in balance for all nutrients and water to be used efficiently. On turf grass there are three micronutrients that are particularly important to maintain green colour and plant vigour</p>
                <p><span>Iron (Fe) : </span> Iron is required for the formation of chlorophyll in the plant cell. </p>
                <p><span>Zinc (Zn) : </span> Zinc is an essential component of several plant enzymes. It is a part of auxins and controls the synthesis of indoleacetic acid which regulates growth compounds. Zinc also affects the intake and efficient use of water by plants.</p>
                <p><span>Manganese (Mn): </span> Manganese serves as an activator for enzymes in plants. Without Manganese, the plants cannot use the iron which they have absorbed. Manganese also accelerates germination and maturity while increasing the availability of phosphorus (P) and calcium (Ca).</p>
                <p><span>Boron (B) : </span> Boron (B) is one of the most important micronutrients affecting membrane stability, B supports the structural and functional integrity of plant cell membranes. Boron-deficiency symptoms first appear at the growing points, and certain soil types are more prone to boron deficiencies.</p>
                <p><span>Copper (Cu) : </span> Copper (Cu) activates enzymes and catalyses reactions in several plant-growth processes. The presence of copper is closely linked to Vitamin A production, and it helps ensure successful protein synthesis.</p>
                <p><span>Molybdenum (Mo) : </span> It’s a trace element found in the soil and is required for the synthesis and activity of the enzyme nitrate reductase.</p>
                <p><span>Chlorine (Cl) : </span> Cl- is required by all plants in small quantities.</p>
                <p><span>Nickel (N) : </span> It contributes to nitrogen fixation and the metabolism of urea (a nitrogen containing compound) and is important for seed germination. Nickel is also important for bacteria and fungi, which are both important for good plant growth.</p>
            </div>
        </div>
    </div>
  )
}

export default Fertilizers