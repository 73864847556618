
import { BsFacebook, BsTelephoneFill } from "react-icons/bs"
import { ImYoutube } from "react-icons/im"
import { GoThreeBars } from "react-icons/go"
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Nevbar = ({ toogle, setToogle, setNev }) => {
    const [cLocation, setCLocation] = useState("home")
    const [toogleBar, setToogleBar] = useState(false)

    useEffect(() => {
        setCLocation(window.location.href.split("/")[3]);
    },[toogle, cLocation, setNev])
    
  return (
    // <div className='main-nev-section'>
    <div className='main-nev'>
        <div className="top" onClick={() => {setToogleBar(false);setCLocation('home')}}>
            <div>
                <Link to="/home" onClick={() => setToogle(1)}>
                    <img src="assets/images/FarmSanta.png" alt="" />
                </Link>
            </div>
            <div>
            <div className="LanguageModule" id="google_translate_element">
             
             </div>
                <img src="assets/images/santa.png" alt="" />
            </div>
        </div>
        <div className="bottom">
            <div className="cnrtls" id={toogleBar ? 'hidden' : ''}>
                <div onClick={() => setToogleBar(false)} className={cLocation==="home"  ? 'active-head' : ''}>
                    <Link to="/home" onClick={() => {setToogle(1);setNev('');setCLocation('home')}}>HOME</Link>
                </div>
                <div onClick={() => setToogleBar(false)} className={cLocation==="about"  ? 'active-head' : ''} >
                    <Link to="/about" onClick={() =>{ setToogle(2);setNev('');setCLocation('about')}}>FARMSANTA TOOL</Link>
                </div>
                {/* <div onClick={() => setToogleBar(false)} className={cLocation==="how-we-help"  ? 'active-head' : ''} >
                    <Link to="/how-we-help" onClick={() =>{ setToogle(2);setNev('');setCLocation('how-we-help')}}>HOW WE HELP</Link>
                </div> */}
                {/* <div onClick={() => setToogleBar(false)} className={cLocation==="farm-santa-advice"  ? 'active-head' : ''} >
                    <Link to="/farm-santa-advice" onClick={() =>{ setToogle(3);setNev('');setCLocation('home')}}>FARM&nbsp;SANTA&nbsp;ADVICE</Link>
                </div> */}
                {/* <div onClick={() => setToogleBar(false)} className={cLocation==="app-features"  ? 'active-head' : ''} >
                    <Link to="/app-features" onClick={() =>{ setToogle(3);setNev('');setCLocation('app-features')}}>FEATURES</Link>
                </div> */}
                <div onClick={() => setToogleBar(false)} className={cLocation==="value-proposition"  ? 'active-head' : ''} >
                    <Link to="/value-proposition" onClick={() =>{ setToogle(4);setNev('');setCLocation('value-proposition')}}>VALUE PROPOSITION</Link>
                </div>
                <div onClick={() => setToogleBar(false)} className={cLocation==="news-and-updates"  ? 'active-head' : ''} >
                    <Link to="/news-and-updates" onClick={() =>{ setToogle(4);setNev('');setCLocation('news-and-updates')}}>NEWS AND UPDATES</Link>
                </div>
                {/* <div onClick={() => setToogleBar(false)} className={cLocation==="fertilizers"  ? 'active-head' : ''} >
                    <Link to="/fertilizers" onClick={() =>{ setToogle(5);setNev('');setCLocation('fertilizers')}}>FERTILIZERS</Link>
                </div> */}
                <div onClick={() => setToogleBar(false)} className={cLocation==="contact-us"  ? 'active-head' : ''} >
                    <Link to="/contact-us" onClick={() =>{ setToogle(6);setNev('');setCLocation('contact-us')}}>CONTACT US</Link>
                </div>
                {/* <div onClick={() => setToogleBar(false)} className={cLocation==="news"  ? 'active-head' : ''} >
                    <Link to="/news" onClick={() =>{ setToogle(8);setNev('');setCLocation('news')}}>NEWS & UPDATES</Link>
                </div> */}
                 {/* <div onClick={() => setToogleBar(false)} className={cLocation==="our-goal"  ? 'active-head' : ''} >
                    <Link to="/our-goal" onClick={() =>{ setToogle(5);setCLocation('home')}}>OUR&nbsp;GOAL</Link>
                </div>
                <div onClick={() => setToogleBar(false)} className={cLocation==="investors"  ? 'active-head' : ''} >
                    <Link to="/investors" onClick={() =>{ setToogle(6);setCLocation('home')}}>INVESTORS</Link>
                </div>
                <div onClick={() => setToogleBar(false)} className={cLocation==="team-details"  ? 'active-head' : ''} >
                    <Link to="/team-details" onClick={() =>{ setToogle(7);setCLocation('home')}}>TEAM&nbsp;DETAILS</Link>
                </div> */}
            </div>
            <div className="bar">
                <div onClick={() => setToogleBar(false)}></div>
                <div>
                    <GoThreeBars onClick={() => setToogleBar(!toogleBar)} id="bar-icons"/>
                </div>
            </div>
        </div>
    </div>
    // </div>
  )
}

export default Nevbar