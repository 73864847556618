import Hero from '../../common/Hero/Hero';
import ReactPaginate from 'react-paginate';
import { TbLocation } from 'react-icons/tb'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SantaAdvice = () => {      
    const itemsPerPage = 12;
        const [currentItems, setCurrentItems] = useState([]);
        const [pageCount, setPageCount] = useState(0);
        const [itemOffset, setItemOffset] = useState(0);

        const data = [
            { id:1, img:"assets/images/card/card1.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:2, img:"assets/images/card/card2.png", date:"12 May, 2022", name: "Crop Seeds Care" },
            { id:3, img:"assets/images/card/card3.png", date:"12 May, 2022", name: "Fertilizer Needs" },
            { id:4, img:"assets/images/card/card4.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:5, img:"assets/images/card/card1.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:6, img:"assets/images/card/card2.png", date:"12 May, 2022", name: "Crop Seeds Care" },
            { id:7, img:"assets/images/card/card3.png", date:"12 May, 2022", name: "Fertilizer Needs" },
            { id:8, img:"assets/images/card/card4.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:9, img:"assets/images/card/card1.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:10, img:"assets/images/card/card2.png", date:"12 May, 2022", name: "Crop Seeds Care" },
            { id:11, img:"assets/images/card/card3.png", date:"12 May, 2022", name: "Fertilizer Needs" },
            { id:12, img:"assets/images/card/card4.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:13, img:"assets/images/card/card1.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:14, img:"assets/images/card/card2.png", date:"12 May, 2022", name: "Crop Seeds Care" },
            { id:15, img:"assets/images/card/card3.png", date:"12 May, 2022", name: "Fertilizer Needs" },
            { id:16, img:"assets/images/card/card4.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:17, img:"assets/images/card/card1.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:18, img:"assets/images/card/card2.png", date:"12 May, 2022", name: "Crop Seeds Care" },
            { id:19, img:"assets/images/card/card3.png", date:"12 May, 2022", name: "Fertilizer Needs" },
            { id:20, img:"assets/images/card/card4.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:21, img:"assets/images/card/card1.png", date:"12 May, 2022", name: "Summer Crop Care" },
            { id:22, img:"assets/images/card/card2.png", date:"12 May, 2022", name: "Crop Seeds Care" },
            { id:23, img:"assets/images/card/card3.png", date:"12 May, 2022", name: "Fertilizer Needs" },
            { id:24, img:"assets/images/card/card4.png", date:"12 May, 2022", name: "Summer Crop Care" },
        ]
        
        useEffect(() => {
          const endOffset = itemOffset + itemsPerPage;
          setCurrentItems(data.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(data.length / itemsPerPage));
        }, []);
      
        const handlePageClick = (event) => {
          const newOffset = (event.selected * itemsPerPage) % data.length;
          setItemOffset(newOffset);
        };
  return (
    <div>
        <Hero img={"assets/images/banners/perfect-green-grass.png"} heading="FarmSanta Advice"/>
        <div className="our-advice-sec" style={{backgroundImage:`url(${"assets/images/Background/Group_1640.png"})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <div>
                <img src="assets/images/Icons_PNG/santa3.png" alt="" />
            </div>
            <div>
                <h3 className='sub-heading-main'>Our Advice</h3>
                <p>FarmSanta app is for farmers to make farming more convenient and easy get quick solution of all the plant related problems . Get expert advice for your crop, Solution for farming related issues and current Market prices of various crops. Through farmsanta farmers can connect and stay updated regarding various issues and get efficient guide for farming. </p>
            </div>
        </div>
        <div className="our-advice-2">
            <div className="underline-head dark">
                <h3>Our Advice</h3>
                <div />
            </div>
            <p className='under-sub-heading'>Get Best Advice for better growth of your crops and Advice to protect your Crops From various issues </p>
            <div className="advice-pagination">
                <div className="advice-card-container">
                    {currentItems.map(dt => {
                        return(
                            <div className='advice-card'>
                                <div className="container" style={{backgroundImage:`url(${dt.img})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                                    <div className="top">
                                        <p>{dt.date}</p>
                                        <p><Link to="/"><TbLocation /></Link></p>
                                    </div>
                                    <div className="bottom">{ dt.name }</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< Prev."
                    renderOnZeroPageCount={null}
                    containerClassName="pagination-st"
                    pageLinkClassName='page-num'
                    previousLinkClassName='page-num-prev'
                    nextLinkClassName='page-num-next'
                    activeLinkClassName='active'
                />
            </div>
        </div>
    </div>
  )
}

export default SantaAdvice