import React from 'react'
import { BsFacebook } from "react-icons/bs"
import { ImYoutube } from "react-icons/im"
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai"
import { Link } from 'react-router-dom'

const Footer = ({ setToogle }) => {

    const scrollToTop = () => {
        window.scroll(0,0)
    }
    const changeOption = (opt) => {
        setToogle(opt)
        window.scroll(0,0)
    }
  return (
    <div className='main-footer' >
        <div>
            <div>
                <Link to="/home" onClick={() => setToogle(100)}>
                    <img src="assets/images/FarmSanta.png" alt="" />
                </Link>
            </div>
            <div>
                <img onClick={scrollToTop} src="assets/images/upArrow.png"/>
            </div>
        </div>
        <div>
            <div>
                <div>
                    <h2>Menu</h2>
                    <Link to='/home'><p onClick={() => changeOption(1) }>Home</p></Link>
                    <Link to='/about'><p onClick={() => changeOption(2) }>Farmsanta Tool</p></Link>
                    <Link to='/value-proposition'><p onClick={() => changeOption(4) }>Value Proposition</p></Link>
                    <Link to='/contact-us'><p onClick={() => changeOption(6) }>Contact Us</p></Link>
                    <a href="https://play.google.com/store/apps/details?id=com.farmsanta.farmer" target="_blank"><p>FarmSanta Farmer App</p></a>
                    <a href="https://play.google.com/store/apps/details?id=com.farmsanta.business" target="_blank"><p>FarmSanta Business App</p></a>
                </div>
            </div>
           
           
            <div>
                
                <div>
                    <div>
                        <h2>Contact</h2>
                    </div>
                        <p>Email : <a href="contact@farmsanta.com">contact@farmsanta.com</a></p>
                        <p>Support Number</p>
                        <p>India - <a href="tel:+913348126531">+91-33-4812-6531</a></p>
                        <p>Singapore - <a href="tel:+6562232192">+65-6223-2192</a></p>
                        <p>Cameroon - <a href="tel:+237650499999">+237 650499999</a></p>
                </div>
                <div style={{marginTop:'1rem'}}>
                    <div>
                        <h2>Social Media</h2>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/FarmSantabyNutriSource?mibextid=ZbWKwL" target="_blank"><BsFacebook style={{fontSize:"1.8rem", color:"#1677F1"}}id="icon"/></a>
                        <a href="https://www.instagram.com/farmsantabynutrisource/" target="_blank"><AiFillInstagram style={{fontSize:"2.2rem", color:"#FFBB36"}}id="icon"/></a>
                        <a href="https://twitter.com/farmsanta_app" target="_blank"><AiFillTwitterCircle style={{fontSize:"2.1rem", color:"#00CCFF"}}id="icon"/></a> 
                        <a href="https://www.youtube.com/@farmsantabynutrisource" target="_blank"><ImYoutube style={{fontSize:"2.1rem", color:"#FF0000"}}id="icon"/></a>
                    </div>
                </div>
            </div>
            
            <div>
                <div>
                    <img src="assets/images/santa.png" alt="" />
                </div>
                <div>
                    <button><a href="https://play.google.com/store/apps/details?id=com.farmsanta.farmer" target='_blank'><img src="assets/images/farmSantaLogo.png" alt="" />Download App</a></button>
                </div>
            </div>
        </div>
        <div>
            <Link to="/privacy-policy" onClick={() => setToogle(100)}>Privacy Policy&nbsp;&nbsp;|</Link>
            <Link to="/terms-and-conditions" onClick={() => setToogle(100)}>&nbsp;&nbsp;Terms & Conditions&nbsp;&nbsp;|</Link>
            <Link to="/cancellation-and-refund-policy" onClick={() => setToogle(100)}>&nbsp;&nbsp;Cancellation and Refund Policy&nbsp;|</Link>
            <Link to="/shipping-and-delivery-policy" onClick={() => setToogle(100)}>&nbsp;&nbsp;Shipping and Delivery Policy </Link>
        </div>
    </div>
  )
}

export default Footer