import React, { useEffect } from 'react'

const RefundPolicy = () => {

    useEffect(() => {
        window.scroll(0,0)
    },[])

  return (
    <div className='home-bg'>
        <div className="container">
                <div>
                    <h2>Refund Policy</h2>
                </div>
                <section>
                    <p>FarmSanta's Return and Exchange Policy offers no return of goods once delivered. If you choose to exchange the item for reason of mismatch of product or receipt of a defective or expired item, you will be provided with a replacement of the item, free of cost. However, an exchange is subject to availability of the product in our stock.</p>
                </section>
              
                <section>
                    <h4>The following EXCEPTIONS and RULES apply to this Policy.</h4>
                    <ol>
                        <li>All items to be returned or exchanged must be unused and in their original condition with all original labels and packaging intact and should not be broken or tampered with.</li>
                        <li>Exchanges are allowed only if your address is serviceable for an Exchange by our logistics team.</li>
                        <li>In case you had purchased a product which has a free gift/offer associated with it and you wish to return the item, maximum of Refunds/Free item MRP will be debited until the satisfactory receipt of all free gift(s)/offer item(s) that are shipped along with it.</li>
                        <li>If you choose to self-ship your returns, kindly pack the items securely to prevent any loss or damage during transit. For all self-shipped returns, we recommend you use a reliable courier service.</li>
                        <li>Perishable goods such as seeds and flowers cannot be returned, if the packet is opened or damaged.</li>
                        <li>Products must be returned within 07 days from the day of delivery, To return your purchase, please mail customer care at contact@farmsanta.com.</li>
                    </ol>
                    <h3>To complete your return, we require a receipt or proof of purchase.</h3>
                    <h3>Please do not send your purchase back to the manufacturer.</h3>
                </section>
                <section>
                    <h4>Refunds (if applicable)</h4>
                    <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</p>
                    <p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a 7 to 10 Working days.</p>
                </section>
                <section>
                    <h4>Late or missing refunds (if applicable)</h4>
                   <ul>
                    <li>If you haven’t received a refund yet, first check your bank account again.</li>
                    <li>Then contact your credit card company, it may take some time before your refund is officially posted.</li>
                    <li>Next contact your bank. There is often some processing time before a refund is posted.</li>
                    <li>If you’ve done all of this and you still have not received your refund yet, please contact us at info@farmsanta.com.</li>
                   </ul>
                </section>
                <section>
                    <h4>Exchanges (if applicable)</h4>
                    <p>We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at contact@farmsanta.com and send your item to:</p>
                   <h3>Farmsanta Pvt Ltd,</h3>
                   <p>Dn-10, DN Block, Sector V, Bidhannagar, Kolkata, West Bengal 700091 </p>
              
                </section>
                <section>
                    <h4>Governing Law</h4>
                    <p>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Kolkata, West Bengal.</p>
                </section>
                <section>
                    <h4>Shipping</h4>
                    <p>To return your product, you should mail your product to:</p>
                    <h3>Farmsanta Pvt Ltd,</h3>
                   <p>Dn-10, DN Block, Sector V, Bidhannagar, Kolkata, West Bengal 700091 </p>
                   <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
                   <p>Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</p>
                </section>
                
                <section>
                    <h4>Contact us</h4>
                    <p>If you have any questions about this Privacy Policy, the practices of this Sites and Mobile Applications, or your dealings with this Sites and Mobile Applications, please contact us at:</p>
                    <div>
                        <h3 >CORPORATE ADDRESS</h3>
                        <div >
                            <h4 >Head Office (Singapore)</h4>
                            <p >NutriSource Pte Ltd 10, Anson Road 22-11 International Plaza Singapore 079903</p>
                        </div>
                        <div >
                            <h4 >Regional Office (Dubai)</h4>
                            <p >NutriSource DMCC 1206, Preatoni Tower, Cluster L, Jumeirah Lake Towers, Dubai, United Arab Emirates</p>
                        </div>
                        <div >
                            <h4 >Regional Office (India)</h4>
                            <p >NutriSource India Private Limited 1st Floor, FL-2, 1/37/6, Bijoygarh Jadavpur LP-55/3/2/5, Kolkata, West Bengal, India 700032</p>
                        </div>
                    </div>
                    <div>
                        <h3 >OFFICES IN AFRICA</h3>
                        <div >
                            <h4 >(Kenya)</h4>
                            <p >NutriSource Kenya Limited P.O. Box 39049-00623, 7th Floor, Victoria Plaza, Parkland Road, Nairobi, Kenya</p>
                        </div>
                        <div >
                            <h4 >(Cameroon)</h4>
                            <p >NutriSource Cameroon Sarl BP 4897, Rue Michelle Brunet Bali Doula Cameroon</p>
                        </div>
                        <div >
                            <h4 >(Togo)</h4>
                            <p >NutriSource Togo Sarl Plot No w2, Plateforme Industrielle d’ Adetikope Parc Industriel d’ Adetikope, Rue NAT No.01 Tsevie Lome, Togo</p>
                        </div>
                    </div>
                    <div>
                        <p>Write to us at <a href="mailto:hey@nutrisourceworld.com">hey@nutrisourceworld.com</a></p>
                    </div>
                </section>
                <section>
                    <p>This document was last updated on : <span>20-Oct-2022</span></p>
                </section>
                <footer >
                    <a href="https://farmsanta.com" target="_blank" >Farmsanta</a>
                    <p>Nutrisource Pte Ltd. &copy; All rights are reserved 2022</p>
                </footer>
        </div>
    </div>
  )
}

export default RefundPolicy