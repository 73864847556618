import './style.css'
import './App.css';
import './Responsive.css'
import { useState } from 'react';
import Nevbar from './common/Nevbar/Nevbar';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Footer from './common/Footer/Footer';
import AppFeatures from './Pages/AppFeatures/AppFeatures';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import AppProduct from './Pages/AppProduct/Product';
import SantaAdvice from './Pages/SantaAdvice/SantaAdvice';
import OurGoal from './Pages/OurGoal/OurGoal';
import Investors from './Pages/Investors/Investors';
import TeamDetails from './Pages/TemDetails/TeamDetails';
import Terms from './Pages/Terms/Terms';
import News from './Pages/News/News';
import ContactUs from './Pages/ContactUs/ContactUs';
import Fertilizers from './Pages/Fertilizers/Fertilizers';
import ValueProposition from './Pages/ValueProposition/ValueProposition';
import ValueChain from './Pages/ValueChain/ValueChain';
import Help from './Pages/Help/Help';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from './Pages/RefundPolicy/RefundPolicy';
import DeliveryPolicy from './Pages/DeliveryPolicy/DeliveryPolicy';

function App() {
  const [toogle, setToogle] = useState(1)
  const [nevSection, setNevSection] = useState('')
  
  return (
    <div className="App">
      <BrowserRouter>
        <Nevbar toogle={ toogle } setToogle={ setToogle } setNev={setNevSection}/>
        <Routes>
          <Route exact path='/' element={<Home />}/>
          <Route exact path='/home' element={<Home setNev={ setNevSection }/>}/>
          <Route exact path='/app-features' element={<AppFeatures nevSection={ nevSection }/>}/>
          <Route exact path='/about' element={<About />}/>
          <Route exact path='/farm-santa-advice' element={<SantaAdvice />}/>
          <Route exact path='/our-goal' element={<OurGoal />}/>
          <Route exact path='/investors' element={<Investors />}/>
          <Route exact path='/news-and-updates' element={<News />}/>
          <Route exact path='/team-details' element={<TeamDetails />}/>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />}/>
          <Route exact path='/terms-and-conditions' element={<Terms />}/>
          <Route exact path='/cancellation-and-refund-policy' element={<RefundPolicy />}/>
          <Route exact path='/shipping-and-delivery-policy' element={<DeliveryPolicy />}/>
          <Route exact path='/contact-us' element={<ContactUs />}/>
          <Route exact path='/fertilizers' element={<Fertilizers />}/>
          <Route exact path='/value-proposition' element={<ValueProposition />}/>
          <Route exact path='/value-chain-model' element={<ValueChain />}/>
          <Route exact path='/product' element={<AppProduct />} />
          {/* <Route exact path='/news' element={<News />}/> */}
          {/* <Route exact path='/updates' element={<Updates />}/> */}
          <Route exact path='/value-chain-model' element={<ValueChain />}/>
          {/* <Route exact path='/how-we-help' element={<Help />}/> */}
        </Routes>
        <Footer toogle={ toogle } setToogle={ setToogle }/>
      </BrowserRouter>
    </div>
  );
}

export default App;
