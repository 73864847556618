import React, { useEffect, useState } from 'react'
import Hero from '../../common/Hero/Hero'
import { HashLink as Redirect } from 'react-router-hash-link'

const AppFeatures = ({ nevSection }) => {
    const [toogleOption, setToogleOption] = useState(1)

    useEffect(() => {
        if(nevSection==='home'){
            window.scroll(0,360)
        } else if (nevSection==='calendar'){
            window.scroll(0,800)
        } else if (nevSection==='advisory'){
            window.scroll(0,1250)
        } else if (nevSection==='pop'){
            window.scroll(0,1740)
        } else if (nevSection==='talk'){
            window.scroll(0,2150)
        } else if (nevSection==='weather'){
            window.scroll(0,2650)
        } else if (nevSection==='market'){
            window.scroll(0,3050)
        } else if (nevSection==='calculator'){
            window.scroll(0,3550)
        }
    },[nevSection])

  return (
    <div className='main-app-features'>
        <div className="features-parent-div">
            <Hero img="assets/images/banners/Rectangle_506.png" heading="Features" unWidth="5"/>
            <div className="option-bar">
                <Redirect to='#farmerQuery' smooth className="btn-div" onClick={() => setToogleOption(1)}><div>Farmer Queries{ toogleOption === 1 && <span className="pointer op-1" /> }</div></Redirect>
                <Redirect to='#cropCalendar' smooth className="btn-div" onClick={() => setToogleOption(2)}><div>Crop Calendar{ toogleOption === 2 && <span className="pointer op-2" /> }</div></Redirect>
                <Redirect to='#cropCrop Advisory' smooth className="btn-div" onClick={() => setToogleOption(3)}><div>Crop Advisory{ toogleOption === 3 && <span className="pointer op-3" /> }</div></Redirect>
                <Redirect to='#popSection' smooth className="btn-div" onClick={() => setToogleOption(4)}><div>POP{ toogleOption === 4 && <span className="pointer op-4" /> }</div></Redirect>
                <Redirect to='#farmerTalk' smooth className="btn-div" onClick={() => setToogleOption(5)}><div>Farm Talk{ toogleOption === 5 && <span className="pointer op-5" /> }</div></Redirect>
                <Redirect to='#weatherReport' smooth className="btn-div" onClick={() => setToogleOption(6)}><div>Weather Information{ toogleOption === 6 && <span className="pointer op-6" /> }</div></Redirect>
                <Redirect to='#marketAnalysics' smooth className="btn-div" onClick={() => setToogleOption(7)}><div>Market Analysis{ toogleOption === 7 && <span className="pointer op-7" /> }</div></Redirect>
                <Redirect to='#fertilizerCalc' smooth className="btn-div" onClick={() => setToogleOption(8)}><div>Fertilizer Calculator{ toogleOption === 8 && <span className="pointer op-8" /> }</div></Redirect>
            </div>
        </div>
        <div className="app-features-sec left">
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1656.png" alt="" />
            </div>
            <div className='content-sec' id='farmerQuery'>
                <h2 className='orange-hd'>Farmer Queries</h2>
                <p>Farmers can register their queries by uploading the issue on FarmSanta App which can be solved in the least time possible. Based on images of infected crops we will provide farmers with accurate diagnosis and solution within few minutes, with the help of AI/ML. We will also provide detailed integrated pest management (IPM) to optimize crop yield and quality and reduce treatment costs.</p>
            </div>
        </div>
        <div className="app-features-sec right" id='cropCalendar'>
            <div className='content-sec'>
                <h2 className='green-hd'>Crop Calendar</h2>
                <p>FS experts bring the calendar of activities for farmers planned crop with the entire set of operations on a real-time basis. The Crop Calendar helps increase the farm produce.</p>
            </div>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1646.png" alt="" />
            </div>
        </div>
        <div className="app-features-sec left" id='cropCrop Advisory'>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1644.png" alt="" />
            </div>
            <div className='content-sec'>
                <h2 className='lGreen-hd'>Crop Advisory</h2>
                <p>Farmers receives crop advisory & crop information right from the crop selection to harvesting with best agronomy solutions (Seed selection/Weather/Irrigation/Pest Control/Market Price/Harvest& post-Harvest management), and that leads farmers to achieve 30-50% increase in crop production. </p>
            </div>
        </div>
        <div className="app-features-sec right" id='popSection'>
            <div className='content-sec'>
                <h2 className='brown-hd'>POP</h2>
                <p>Farmer can Explore the Science & art of Agriculture with Pop’s for each crop at them convenient time.</p>
            </div>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1643.png" alt="" />
            </div>
        </div>
        <div className="app-features-sec left" id='farmerTalk'>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1652.png" alt="" />
            </div>
            <div className='content-sec'>
                <h2 className='orange-hd'>Farmer Talk</h2>
                <p>Using our FarmSanta application, Farmer can connect with Agri experts and other progressive farmers across the country to learn and discuss latest innovations in the farming sector, strategies to improve lives in rural communities, effective and sustainable farming strategies, ideal ways to increase yields and how to use resources more efficiently and a lot more. We help them to get accurate, quick and free solutions to all their day-to-day problems related to farming, irrigation, pest, nutrient, Market etc. </p>
            </div>
        </div>
        <div className="app-features-sec right" id='weatherReport'>
            <div className='content-sec'>
                <h2 className='lOrange-hd'>Weather Information</h2>
                <p>FS is having the weather widget which provides 7 days weather forecast to farmers in advance. Farmers can access weather forecasts based on their location to make smart agriculture choices. Weather updates include rainfall, temperature & humidity, wind speed & direction.</p>
            </div>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1649.png" alt="" />
            </div>
        </div>
        <div className="app-features-sec left" id='marketAnalysics'>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1647.png" alt="" />
            </div>
            <div className='content-sec'>
                <h2 className='llGreen-hd'>Market Analysis</h2>
                <p>Farmer Can get instant access to market rates of their preferred crops in the geography selected by them. These prices are updated from time-to-time so that they can obtain the latest and accurate information.</p>
            </div>
        </div>
        <div className="app-features-sec right" id='fertilizerCalc'>
            <div className='content-sec'>
                <h2 className='lOrange-hd'>Fertilizer Calculator</h2>
                <p>STR and Non STR based fertilizer calculator for crops and farms size.</p>
            </div>
            <div className='ind-img'>
                <img src="assets\images\Icons_PNG\Group_1655.png" alt="" />
            </div>
        </div>
    </div>
  )
}

export default AppFeatures