import React, { useState } from 'react'
import Hero from '../../common/Hero/Hero'
import ReactPlayer from 'react-player'
import videwOne from '../../assets/activities.mp4'
import { AiFillRightCircle } from 'react-icons/ai'
import NEWS_DATA from '../../MOC_DATA/NEWS_DATA'
import Updates from '../Updates/Updates'

const News = () => {
  const [toogle, setToogle] = useState(1)
  const [showMore, setShowMore] = useState(false)
  const [readMoreIndx, setReadMoreIndx] = useState()
  
  const showMoreFun = (index) => {
      setReadMoreIndx(index)
  }
  return (
    <div className='news-page'>
      <Hero img="assets/images/banners/tractor.png" heading="News & Updates" unWidth="10"/>
      <div className="container_news">
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail8.jpg' alt='Thumbnail' />} url='videos/larakhsaindia.mp4' controls muted loop/>
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail2.jpg' alt='Thumbnail' />}  url='videos/FS Video_1.mp4' controls muted loop/>
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail1.jpg' alt='Thumbnail' />} url='videos/FS Video Jan 2023.mp4' controls muted loop/> 
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail3.jpg' alt='Thumbnail' />} url='videos/FarsmSanta Video Cameroon.mp4' controls muted loop/>
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail4.jpg' alt='Thumbnail' />} url='videos/FarmSanta Now at Kribi, Cameroon.mp4' controls muted loop/>
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail5.jpg' alt='Thumbnail' />} url='videos/FarmSanta Reels.mp4' controls muted loop/>
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail6.jpg' alt='Thumbnail' />} url='videos/activities.mp4' controls muted loop/>
        <ReactPlayer playing light={<img src='/videos/thumbnails/thumbnail7.jpg' alt='Thumbnail' />} url='videos/FarmSantaAppVideo.mp4' controls muted loop/>
      </div>
      {/* <div className="tooglebar">
          <div onClick={() => setToogle(1)} className={toogle===1 ? 'active' : ''}>News</div>
          <div onClick={() => setToogle(2)} className={toogle===2 ? 'active' : ''}>Updates</div>
      </div> */}
      {/* { toogle === 1 && <><div className="container">
          { NEWS_DATA?.slice(0,showMore ? NEWS_DATA.length : 9).map((dt, index) => <div className="card" key={index}>
            <div>
              <img src={dt.img} alt="" />
            </div>
            <div>
              <h3>{dt.heading}</h3>
              <p>{dt.content.slice(0,(readMoreIndx=== index) ? dt.content.length : 130)}{( readMoreIndx !== index && dt.content.length > 129) ? <span onClick={() => showMoreFun(index)}>&nbsp;...read&nbsp;more</span> : '.'}</p>
            </div>
          </div> )}
      </div>
      { (NEWS_DATA?.length > 9 && !showMore) && <div className="read-more">
        <h2 onClick={() => setShowMore(true)}>Read More</h2>
      </div> } </> }
      { toogle === 2 && <Updates /> } */}
    </div>
  )
}

export default News