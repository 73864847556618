import Slider from 'react-slick'
import { FiArrowRight } from "react-icons/fi"
import { BsFacebook } from "react-icons/bs"
import { ImYoutube } from "react-icons/im"
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai"
import { Settings, Settingsteam } from './Style'
import { Link, useNavigate } from 'react-router-dom'

const Home = ({ setNev }) => {
    
    // const mainSliderData = [
    //     { id:1, image:"assets/images/plating.jpg", label:"Summer Crops Care" },
    //     { id:2, image:"assets/images/carying.jpg", label:"Crop Seeds care " },
    //     { id:3, image:"assets/images/menimage.jpg", label:"Summer Crops Care" },
    //     { id:4, image:"assets/images/calculation.jpg", label:"Fertilizer Need" },
    //     { id:5, image:"assets/images/plating.jpg", label:"Summer Crops Care" },
    //     { id:6, image:"assets/images/carying.jpg", label:"Crop Seeds care " },
    // ]
    // const sliderCard = mainSliderData.map(img => <div className='image-card' key={img.id}>
    //     <img src={img.image} alt="" />
    //     <div><p>{img.label}</p></div>
    // </div>)

    const agriSliderData = [
        { id:1, image:"assets/images/cropProtection.jpg", label:"Crop Protection" },
        { id:2, image:"assets/images/carying.jpg", label:"Seeds" },
        { id:3, image:"assets/images/menimage.jpg", label:"Fertilizers" },
        { id:4, image:"assets/images/calculation.jpg", label:"Equipment" },
        { id:5, image:"assets/images/plating.jpg", label:"Irrigations" },
        { id:6, image:"assets/images/finance.jpg", label:"Finance" },
    ]
    const agriSliderCard = agriSliderData.map(img => <div className='image-card' key={img.id}>
        <img src={img.image} alt="" />
        <div><p>{img.label}</p></div>
    </div>)

    const goalSlideData = [
        { id:1, img:"assets/images/leaf.png", label1:"Make Farming more easy with technology" },
        { id:2, img:"assets/images/leaf.png", label1:"2nd Slider" },
        { id:3, img:"assets/images/leaf.png", label1:"3rd Slider" },
        { id:4, img:"assets/images/leaf.png", label1:"4th Slider" },
    ]
    const goalSliderCard = goalSlideData.map( dt =>  <div key={dt.id}>
        <div className="teamSlider">
            <div>
                <div className='img-div2'>
                    <img src={dt.img} alt="" />
                </div>
                <div>
                    <p>{dt.label1}</p>
                </div>
            </div>
        </div>
    </div>)

    const teamSlidetData = [
        { id:1, img:"assets/images/menimage.jpg", label1:"Name Of Member", label2:"Role: Role of Member" },
        { id:2, img:"assets/images/calculation.jpg", label1:"2nd Slider", label2:"2nd Slider Line 2" },
        { id:3, img:"assets/images/plating.jpg", label1:"3rd Slider", label2:"3rd Slider Line 2" },
        { id:4, img:"assets/images/carying.jpg", label1:"4th Slider", label2:"4th Slider Line 2" },
    ]
    const teamSliderCard = teamSlidetData.map( dt =>  <div key={dt.id}>
        <div className="teamSlider">
            <div>
                <div className='img-div'>
                    <img src={dt.img} alt="" />
                </div>
                <div>
                    <p>{dt.label1}</p>
                    <p>{dt.label2}</p>
                </div>
            </div>
        </div>
    </div>)

    const investorsSlidetData = [
        { id:1, img:"assets/images/menimage.jpg" , img2:"assets/images/menimage.jpg", img3:"assets/images/menimage.jpg"},
        { id:2, img:"assets/images/menimage.jpg" , img2:"assets/images/menimage.jpg", img3:"assets/images/menimage.jpg"},
        { id:3, img:"assets/images/menimage.jpg" , img2:"assets/images/menimage.jpg", img3:"assets/images/menimage.jpg"},
        { id:4, img:"assets/images/menimage.jpg" , img2:"assets/images/menimage.jpg", img3:"assets/images/menimage.jpg"},
    ]
    const investorsSliderCard = investorsSlidetData.map( dt =>  <div key={dt.id}>
        <div className="teamSlider">
            <div>
                <div className='img-cont'>
                    <img src={dt.img} alt="" />
                    <img src={dt.img2} alt="" />
                    <img src={dt.img3} alt="" />
                </div>
            </div>
        </div>
    </div>)

const nevigate = useNavigate()

const nevigateFeatures = (opt) => {
    if(opt==='talk'){
        setNev('talk')
    } else if(opt==='queries'){
        setNev('home')
    } else if(opt==='advisory'){
        setNev('advisory')
    } else if(opt==='pop'){
        setNev('pop')
    } else if(opt==='analysis'){
        setNev('analysis')
    } else if(opt==='calendar'){
        setNev('calendar')
    } else if(opt==='weather'){
        setNev('weather')
    } else if(opt==='calculator'){
        setNev('calculator')
    } else if(opt==='market'){
        setNev('market')
    }
    nevigate('/app-features')
}

const nevigatePage = () => {
    nevigate('/value-chain-model')
}

  return (
    <div>
        <div className="hero-section-one" style={{backgroundImage:`url(${"assets/images/banners/Rectangle_130.png"})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <div>
                <h2>Empowering and Connecting Marginal Farmers Digitally to Provide Advisory Services and Market Access for Improving Farming Livelihood and Promote Sustainable Agriculture.</h2>
                <button><a href='https://play.google.com/store/apps/details?id=com.farmsanta.farmer' target='_target'><img src="assets/images/farmSantaLogo.png" alt="" />Download App</a></button>
            </div>
            <div className="float-section">
                <p>A full-stack Digital solution to farmers: </p>
                <p>FarmSanta is a unique end to end Agritech platform developed by Singapore based Organization specialized in agriculture value chain with the philosophy to enable and empower farmers through technology, information, Advisory and data science. </p>
            </div>
        </div>
        <div className="hero-section-two" style={{backgroundImage:`url(${"assets/images/banners/pexels-fabien-burgue-2083502.png"})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <div className="underline-head light">
                <h3>FarmSanta Core objective</h3>
                <div />
            </div>
            <div>
                <p style={{marginTop: '2rem', fontWeight:'bold'}}>"Serving Farmers and Improving Farming Livelihood."</p>
                <p style={{fontWeight:'bold'}}>"Enhancing crop productivity, reduce Nutrient Loss and improve price realization of Agri produce."</p>
                <p >We have developed a unique model mixed of Physical Touch points and Digital Ecosystem and we call it PHYGITAL Model.</p>
                <p>We are developing an integrated Digital Ecosystem to connect small and marginal farmers across regions, Govt and regulatory Bodies and the Trade/Service providers to bring all important agri value chain stakeholders in a single platform to offer farmers all kinds of services and market access for improving farming livelihood and promote sustainable agriculture.</p>
                <p>Farmsanta also has Physical help centres in various locations which we call as Farmsanta Excellence Centre where farmers can visit and get all necessary supports like full range of Agri Inputs, Crop Advisory, Soil testing, Training, market price advisory & market linkages. </p>
                <p>Our platform proposes multiple key traceability solutions to digitize, secure and leverage valuable data, enabling safer, more sustainable, more efficient, and transparent supply chains. We capture and empower real-time data, enabling stakeholders to track and trace all aspects of the product journey from raw materials to the consumer.</p>
            
            </div>
            {/* <button>Know About Us <FiArrowRight style={{marginLeft:"9px"}}/></button> */}
        </div>
        {/* <div className="client-section">
            <div >
                <div className="underline-head light">
                    <h3>Our Team</h3>
                <div />
                </div>
                <Slider {...Settingsteam}> {teamSliderCard} </Slider>
            </div>
            <div >
                <div className="underline-head light">
                    <h3>Our Goal</h3>
                <div />
                </div>
                <Slider {...Settingsteam}> {goalSliderCard} </Slider>
            </div>
            <div >
                <div className="underline-head light">
                    <h3>Our Investors</h3>
                <div />
                </div>
                <Slider {...Settingsteam}> {investorsSliderCard} </Slider>
            </div>
           
        </div> */}
        <div className="features-section" style={{backgroundImage:`url(${"assets/images/Background/mask_group.png"})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                <div className="underline-head orange">
                    <h3>Features of Farmsanta Application</h3>
                    <div />
                </div>
                <div className="features-container">
                    <div className="card">
                        <div className="icon">
                            <img src="assets/images/features/img_2.png" alt="" />
                        </div>
                        <div className='container'>
                            <h2>Farm Advisory</h2>
                            <div>
                                <div onClick={() => nevigateFeatures('talk')}>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Farm Talk</span>
                                </div>
                                <div onClick={() => nevigateFeatures('queries')}>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Farmer Query</span>
                                </div>
                                <div onClick={() => nevigateFeatures('pop')}>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>POP</span>
                                </div>
                                <div onClick={() => nevigateFeatures('advisory')}>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Crop Advisory</span>
                                </div>
                                <div className="arrow">
                                    <Link to='/app-features'><img src="assets/images/features/arrow.png" alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <img src="assets/images/features/img_3.png" alt="" />
                        </div>
                        <div className='container'>
                            <h2>Partner Engagement services</h2>
                            <div>
                                <div>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Inputs</span>
                                </div>
                                <div>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Implements</span>
                                </div>
                                <div>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Equipments</span>
                                </div>
                                <div>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Finance</span>
                                </div>
                                <div className="arrow">
                                    <Link to='/app-features'><img src="assets/images/features/arrow2.png" alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <img src="assets/images/features/img_1.png" alt="" />
                        </div>
                        <div className='container'>
                            <h2>Market Linkage</h2>
                            <div>
                                <div onClick={() => nevigateFeatures('market')}>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Market Price</span>
                                </div>
                                <div>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Buyer Access</span>
                                </div>
                                <div>
                                    <img src="assets/images/features/leaf.png" alt="" />
                                    <span>Traceability</span>
                                </div>
                                <div className="arrow">
                                    <Link to='/app-features'><img src="assets/images/features/arrow3.png" alt="" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button>View Features Detail</button> */}
        </div>
        <div className="agri-advice-section" style={{backgroundImage:`url(${"assets/images/banners/pexels-todd-trapani-1382102.png"})`, objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <div className="underline-head orange">
                <h3>Our Value chain model</h3>
                <div />
            </div>
            <div>
                <p>Farmsanta is creating the integrated Agri-food Value Chain to synergize complementary skillsets of all key stakeholders in the business process- Farmers, Govt and Trade. This platform is designed to increase process efficiency and competitive advantage through collaboration in a venture that links farmers, Agri specialists, Govt, NGOs, processors, marketers, suppliers, food service companies, retailers, financiers and more to bring full range of services and support to the marginal farmers. </p>
                {/* <p> Farmsanta allows businesses to respond to the marketplace by linking big data, Knowledge, services, production, processing, and marketing activities to the market demands.</p> */}
            </div>
            <div className="slider">
                <Slider {...Settings}> {agriSliderCard} </Slider>
            </div>
            <div>
                <button onClick={nevigatePage}>View All <FiArrowRight style={{marginLeft:"9px"}}/></button>
            </div>
            <div className="agri-advice-footer">
                {/* <Link to="/" target="_blank"><BsFacebook style={{fontSize:"1.3rem", color:"white"}}id="icon"/></Link>
                <Link to="/" target="_blank"><AiFillInstagram style={{fontSize:"1.6rem", color:"white"}}id="icon"/></Link>
                <Link to="/" target="_blank"><AiFillTwitterCircle style={{fontSize:"1.5rem", color:"white"}}id="icon"/></Link>
                <Link to="/" target="_blank"><ImYoutube style={{fontSize:"1.5rem", color:"white"}}id="icon"/></Link> */}
            </div>
        </div>
        {/* <div className="nutri-source-section" style={{backgroundImage:`url(${"assets/images/banners/Rectangle_499.png"})`,position:"relative", objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <div className="container"  style={{backgroundImage:`url(${"assets/images/banners/web_banners/group_1639.png"})`,position:"relative", objectFit:"container", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
                <div>
                    <img src="assets/images/santa.png" alt="" />
                </div>
                <div>
                <source srcSet="assets/images/fsLogo-2.png" media="(max-width:765px)" />
                    <img src="assets/images/FarmSanta.png" alt="" />
                    <p>NutriSource’s core foundation is laid on strong and comprehensive deep domain expertise, specific geographic exposure and interface with farming communities in developing markets based on over 150 Years of aggregated experience of its core team.</p> <br />
                    <p>NutriSource is purely knowledge and technology driven service-oriented farm input and Advisory service provider Organization engaging with farmers at grass-root level. Its focus remains only in emerging markets of Africa and SE Asia.</p>
                </div>
            </div>
        </div> */}
        {/* <div className="contact-us-section">
            <div>
                <div className="underline-head orange">
                    <h3>Contact&nbsp;Us</h3>
                    <div />
                </div>
                <form action="submit">
                    <p>Interested to know more about us ask us your question Fill the below details and connect with us </p>
                    <div className="flex">
                        <div>
                            <label htmlFor="userName">Full Name</label>
                            <input type="text" name="" id="userName" placeholder='Name' />
                        </div>
                        <div>
                            <label htmlFor="emailID">Main ID</label>
                            <input type="text" name="" id="emailID" placeholder='Mail@gmail.com' />
                        </div>
                    </div>
                    <div className='full-width'>
                        <label htmlFor="messageField">Message</label>
                        <textarea name="" id="messageField" cols="30" placeholder='Type your Message Here'></textarea>
                    </div>
                    <button>Send Message <FiArrowRight style={{marginLeft:"0.7rem"}}/></button>
                </form>
            </div>
            <div style={{backgroundImage:`url(${"assets/images/cropHand.png"})`,position:"relative", objectFit:"cover",backgroundSize: "80% 100%", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            </div>
        </div> */}
        {/* <div className="contact-no-section">
            <div>
                <img src="assets/images/ladyBanner.png" alt="" />
            </div>
            <div><p>Have any Questions? Contact us Now (+91-8903456120) </p></div>
        </div> */}
    </div>
  )
}

export default Home