import React from 'react'
import Hero from '../../common/Hero/Hero'

const Investors = () => {
  return (
    <div>
        <div className="inv-hero-sec">
            <Hero img={"assets/images/banners/hand_shake_banner.png"}
                heading="Our Investors" 
                subHeading="Get Best Advice for better growth of your crops and Advice to protect your Crops From various issues"
                />
        <div className="investors-face-container">
            <div><img src="assets/images/Icons_PNG/group_1661.png" alt="" /></div>
            <div><img src="assets/images/Icons_PNG/group_1661.png" alt="" /></div>
            <div><img src="assets/images/Icons_PNG/group_1661.png" alt="" /></div>
            <div><img src="assets/images/Icons_PNG/group_1661.png" alt="" /></div>
            <div><img src="assets/images/Icons_PNG/group_1661.png" alt="" /></div>
            <div><img src="assets/images/Icons_PNG/group_1661.png" alt="" /></div>
        </div>
        </div>
    </div>
  )
}

export default Investors