import React from 'react'
import Hero from '../../common/Hero/Hero'

const OurGoal = () => {
  return (
    <div>
        <Hero img={"assets/images/banners/Rectangle_159.png"} heading="Our Goals"/>
        <div className="goals-section">
            <div>
                <img src="assets/images/Images/image_2.png" alt="" />
            </div>
            <div>
                <h3>Our Goals</h3>
                <p>FarmSanta app is for farmers to make farming more convenient and easy get quick solution of all the plant related problems . Get expert advice for your crop, Solution for farming related issues and current Market prices of various crops. Through farmsanta farmers can connect and stay updated regarding various issues and get efficient guide for farming.</p>
            </div>
        </div>
        <div className="goals-chart">
            <div className="underline-head orange">
                <h3>Our Goals</h3>
                <div />
            </div>            
            <p className='under-sub-heading'>Get Best Advice for better growth of your crops and Advice to protect your Crops From various issues </p>
            
            <img src="assets/images/SVG_Assets/goals_Graphic.svg" alt="" />
        </div>
    </div>
  )
}

export default OurGoal