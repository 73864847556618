import React, { useEffect } from 'react'
import Hero from '../../common/Hero/Hero'

const ValueChain = () => {
    useEffect(() => {
        window.scroll(0,0)
    },[])
  return (
    <div className='value-chain'>
        <Hero img="assets/images/banners/Rectangle_159.png" unWidth="6" heading="Value Chain Model"/>
        <div className='container'>
            <div>
                <img src="assets/images/farmingPeople.jpg" alt="" />
            </div>
            <div>
                <p>Farmsanta is creating the integrated Agri-food Value Chain to synergize complementary skillsets of all key stakeholders in the business process- Farmers, Govt and Trade. This platform is designed to increase process efficiency and competitive advantage through collaboration in a venture that links farmers, Agri specialists, Govt, NGOs, processors, marketers, suppliers, food service companies, retailers, financiers and more to bring full range of services and support to the marginal farmers. </p>
                <p>Farmsanta/NSW Value Chain can be defined as a strategic partnership among inter-dependent businesses  and various stakeholders that collaborate to progressively create value at the farm and for the final consumer resulting in a collective competitive advantage.</p>
                <p>Farmsanta allows businesses to respond to the marketplace by linking big data, Knowledge, services, production, processing, and marketing activities to the market demands.</p>
                <h2>Solution for Farming Community: </h2>
                <p>Farmsanta App and its phygital model provide frequent crop reminder notifications, SMS and voice calls in local language to the farmer specific to their crop requirements for the entire crop cycle covering all critical aspects of farming. Also they get 365 days, 24x7 crop advisory services on field as well as through mobile app for best available crop consultation. It also provides farmers with the flexibility to place order for agri inputs through our App and even they can get real time local market price information of their produces which enables them to get real time market access without any intermediatory. They even could get customized advisory services through automated messages, crop calendar notifications and voice calls based on real-time information.</p>
            </div>
        </div>
    </div>
  )
}

export default ValueChain