import React, { useEffect } from 'react'

const Terms = () => {

    useEffect(() => {
        window.scroll(0,0)
    },[])

  return (
    <div className='home-bg'>
        <div className="container">
                <div>
                    <h2>Terms and Conditions</h2>
                </div>
                <section>
                   <p>Please read the following terms and conditions very carefully as your use of service is subject to your acceptance of and compliance with the following terms and conditions ("Terms").</p>
                   <p>By subscribing to or using any of our services you agree that you have read, understood and are bound by the Terms, regardless of how you subscribe to or use the services. If you do not want to be bound by the Terms, you must not subscribe to or use our services. These Terms and various other policies are binding as per the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 formulated under the Information Technology Act of 2000.</p> 
                    <p>In these Terms, references to "you", "User" shall mean the end user accessing the Website and the Android Applications, its contents and using the Services offered through the Website and the Android Applications, "Service Providers" mean independent third party service providers, and "we", "us" and "our" shall mean FarmSanta Private Limited and its affiliates.</p>
                </section>
                <section>
                    <h4 >1. Introduction:</h4>
                    <ol>
                        <li> www.farmsanta.com ("Website"), FarmSanta Support, And FarmSanta Business app are an Internet based content and e-commerce portal, a company incorporated under the laws of India.</li>
                        <li>Use of the Website and the Android Applications are offered to you conditioned on acceptance without modification of all the terms, conditions and notices contained in these Terms, as may be posted on the Website and the Android Applications from time to time. Farmsanta.com at its sole discretion reserves the right not to accept a User from registering on the Website without assigning any reason thereof.</li>

                    </ol>

                </section>
                <section>
                    <h4>2. User Account, Password, and Security:</h4>
                    <p>You will receive a password and account designation upon completing the Website's and Android Applications’ registration process. You are responsible for maintaining the confidentiality of the password and account and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Farmsanta.com of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. FarmSanta cannot and will not be liable for any loss or damage arising from your failure to comply with this Section 2.</p>
                </section>
                <section>
                    <h4>3. Services Offered:</h4>
                    <p>FarmSanta provides a number of Internet-based services through the Web Site and Android Applications (all such services, collectively, the "Service"). One such service enables users to purchase original products such as seeds and accessories from various supplier brands (collectively, "Products"). The Products can be purchased through the Website through various methods of payments offered. The sale/purchase of Products shall be additionally governed by specific policies of sale, like cancellation policy, return policy, etc., and all of which are incorporated here by reference. In addition, these terms and policies may be further supplemented by Product specific conditions, which may be displayed on the webpage of that Product.</p>
                </section>
                <section>
                    <h4>4. Privacy Policy:</h4>
                    <p>The User hereby consents, expresses and agrees that he has read and fully understands the Privacy Policy of Farmsanta.com. The user further consents that the terms and contents of such Privacy Policy are acceptable to him.</p>
                 <a href="./privacy-policy" >Read More</a>
                </section>
                <section>
                    <h4>5. Limited User:</h4>
                    <p>The User agrees and undertakes not to reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website and the Android Applications. Limited reproduction and copying of the content of the Website and the Android Applications are permitted provided that Farmsanta's name is stated as the source and prior written permission of Farmsanta.com is sought. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website is not permitted.</p>
                  
                </section>
                <section>
                    <h4>6. User Conduct and Rules:</h4>
                    <p>You agree and undertake to use the Website and the Service only to post and upload messages and material that are proper. By way of example, and not as a limitation, you agree and undertake that when using a Service, you will not:</p>
                    <ul>
                        <li>(a) defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others;</li>
                        <li>(b) publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</li>
                        <li>(c) upload files that contain software or other material protected by intellectual property laws unless you own or control the rights thereto or have received all necessary consents; you own or control the rights thereto or have received all necessary consents;</li>
                        <li>(d) upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer;</li>
                        <li>(e) conduct or forward surveys, contests, pyramid schemes or chain letters;</li>
                        <li>(f) download any file posted by another user of a Service that you know, or reasonably should know, cannot be legally distributed in such manner;</li>
                        <li>(g) falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</li>
                        <li>(h) violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;</li>
                        <li>(i) violate any applicable laws or regulations for the time being in force in or outside India; and</li>
                        <li>(j) violate, abuse, unethically manipulate or exploit, any of the terms and conditions of this Agreement or any other terms and conditions for the use of the Website contained elsewhere.</li>
                    </ul>
                </section>
                <section>
                    <h4>7. User Warranty and Representation:</h4>
                    <p>The user guarantees, warrants, and certifies that you are the owner of the content which you submit or otherwise authorised to use the content and that the content does not infringe upon the property rights, intellectual property rights or other rights of others. You further warrant that to your knowledge, no action, suit, proceeding, or investigation has been instituted or threatened relating to any content, including trademark, trade name service mark, and copyright formerly or currently used by you in connection with the Services rendered by Farmsanta.com.</p>
                </section>
                <section>
                    <h4>8. Exactness Not Guaranteed:</h4>
                    <p>FarmSanta hereby disclaims any guarantees of exactness as to the finish and appearance of the final Product as ordered by the user. The quality of any products, Services, information, or other material purchased or obtained by you through the Website may not meet your expectations. Alterations to certain aspects of your order such as the supplier brand, quantity etc. may be required due to limitations caused by availability of product difference in quantity charts of respective brands etc. In this instance you agree that Farmsanta.com will send an approval request via the email address, which you submitted when placing your order. If you do not agree with the requested change you retain the right to reject the requested product change by replying to it within 10 days of it being sent to you.</p>
                </section>
                <section>
                    <h4>9. Intellectual Property Rights:</h4>
                    <ul>
                        <li>(a) Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, FarmSanta owns all Intellectual Property Rights to and into the Website and the Android Applications, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, trademarks, trade names, service marks, designs, know-how, trade secrets and inventions (whether patentable or not), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks. You acknowledge and agree that you shall not use, reproduce or distribute any content from the Website and the Android Applications belonging to FarmSanta without obtaining authorization from Farmsanta.com.</li>
                        <li>(b) Notwithstanding the foregoing, it is expressly clarified that you will retain ownership and shall solely be responsible for any content that you provide or upload when using any Service, including any text, data, information, images, photographs, music, sound, video or any other material which you may upload, transmit or store when making use of our various Service. However, with regard to the product customization Service (as against other Services like blogs and forums) you expressly agree that by uploading and posting content on to the Website for public viewing and reproduction/use of your content by third party users, you accept the User whereby you grant a non-exclusive license for the use of the same.</li>
                    </ul>
                </section>
                <section>
                    <h4>10. Links To Third Party Sites:</h4>
                    <p>The Website may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Farmsanta.com or the Website and the Android Applications and FarmSanta is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. FarmSanta is not responsible for any form of transmission, whatsoever, received by you from any Linked Site. Farmsanta.com is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Farmsanta.com or the Website and the Android Applications of the Linked Sites or any association with its operators or owners including the legal heirs or assigns thereof. The users are requested to verify the accuracy of all information on their own before undertaking any reliance on such information.</p>
                    <p>Our store is hosted on Shopify Inc and managed by FarmSanta. They provide us services for online e-commerce platform that allows us to sell our products and services to you and web page use Shopify services for secure and safe transition and Gateway Integration for payment transition.</p>
                </section>
                <section>
                    <h4>11. Disclaimer Of Warranties/Limitation Of Liability:</h4>
                   <ul>
                    <li>(a) FarmSanta has endeavoured to ensure that all the information on the Website and the Android Applications are correct, but FarmSanta neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product or Service. In no event shall Farmsanta.com be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from: (a) the use or the inability to use the Services or Products; (b) unauthorized access to or alteration of the user's transmissions or data; (c) any other matter relating to the services; including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Website or Service. Neither shall FarmSanta be responsible for the delay or inability to use the Website and the Android Applications or related services, the provision of or failure to provide Services, or for any information, software, products, services and related graphics obtained through the Website and the Android Applications, or otherwise arising out of the use of the website and the Android Applications, whether based on contract, tort, negligence, strict liability or otherwise. Further, FarmSanta shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the website that may occur due to technical reasons or for any reason beyond FarmSanta's control. The user understands and agrees that any material and/or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data.</li>
                   <li>(b) The performance of the product is subject to usage as per manufacturer guidelines. Read enclosed leaflet of the products carefully before use. The use of this information is at the discretion of user.</li>
                   </ul>
                </section>
                <section>
                    <h4>12. Indemnification:</h4>
                    <p>You agree to indemnify, defend and hold harmless FarmSanta from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Farmsanta.com that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by you pursuant to these Terms.</p>
                </section>
                <section>
                    <h4>13. Pricing:</h4>
                    <p>Prices for products are described on our Website and the Android Applications and are incorporated into these Terms by reference. All prices are in Indian rupees. Prices, products and Services may change at FarmSanta's discretion.</p>
                </section>
                <section>
                    <h4>14. Shipping:</h4>
                    <p>Title and risk of loss for all products ordered by you shall pass on to you upon FarmSanta's shipment to the shipping carrier. Rules on COD vary based on transaction value, products, shipping location and other relevant parameters. FarmSanta shall retain the right to offer/deny COD for specific cases.</p>
                </section>
                <section>
                    <h4>15. Termination:</h4>
                    <ul>
                        <li>(a) FarmSanta may suspend or terminate your use of the Website and the Android Applications or any Service if it believes, in its sole and absolute discretion that you have breached, violated, abused, or unethically manipulated or exploited any term of these Terms or anyway otherwise acted unethically.</li>
                        <li>(b) Notwithstanding Section 15.a above, these Terms will survive indefinitely unless and until FarmSanta chooses to terminate them.</li>
                        <li>(c) If you or Farmsanta.com terminates your use of the Website and the Android Applications or any Service, FarmSanta may delete any content or other materials relating to your use of the Service and FarmSanta will have no liability to you or any third party for doing so.</li>
                        <li>(d) You shall be liable to pay for any Service or product that you have already ordered till the time of Termination by either party whatsoever. Further, you shall be entitled to your royalty payments as per the User License Agreement that has or is legally deemed accrued to you.</li>
                    </ul>
                </section>
                <section>
                    <h4>17. Severability:</h4>
                    <p>If any provision of the Terms is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision, and all other provisions of these Terms shall continue to be in full force and effect.</p>
                </section>
                <section>
                    <h4>18. Report Abuse:</h4>
                    <p>As per these Terms, users are solely responsible for every material or content uploaded on to the Website. Users can be held legally liable for their contents and may be held legally accountable if their contents or material include, for example, defamatory comments or material protected by copyright, trademark, etc. If you come across any abuse or violation of these Terms, please report to contact@farmsanta.com.</p>
                </section>
                <section>
                    <h4>Contact us</h4>
                    <p>If you have any questions about this Privacy Policy, the practices of this Sites and Mobile Applications, or your dealings with this Sites and Mobile Applications, please contact us at:</p>
                    <div>
                        <h3 >CORPORATE ADDRESS</h3>
                        <div >
                            <h4 >Head Office (Singapore)</h4>
                            <p >NutriSource Pte Ltd 10, Anson Road 22-11 International Plaza Singapore 079903</p>
                        </div>
                        <div >
                            <h4 >Regional Office (Dubai)</h4>
                            <p >NutriSource DMCC 1206, Preatoni Tower, Cluster L, Jumeirah Lake Towers, Dubai, United Arab Emirates</p>
                        </div>
                        <div >
                            <h4 >Regional Office (India)</h4>
                            <p >NutriSource India Private Limited 1st Floor, FL-2, 1/37/6, Bijoygarh Jadavpur LP-55/3/2/5, Kolkata, West Bengal, India 700032</p>
                        </div>
                    </div>
                    <div>
                        <h3 >OFFICES IN AFRICA</h3>
                        <div >
                            <h4 >(Kenya)</h4>
                            <p >NutriSource Kenya Limited P.O. Box 39049-00623, 7th Floor, Victoria Plaza, Parkland Road, Nairobi, Kenya</p>
                        </div>
                        <div >
                            <h4 >(Cameroon)</h4>
                            <p >NutriSource Cameroon Sarl BP 4897, Rue Michelle Brunet Bali Doula Cameroon</p>
                        </div>
                        <div >
                            <h4 >(Togo)</h4>
                            <p >NutriSource Togo Sarl Plot No w2, Plateforme Industrielle d’ Adetikope Parc Industriel d’ Adetikope, Rue NAT No.01 Tsevie Lome, Togo</p>
                        </div>
                    </div>
                    <div>
                        <p>Write to us at <a href="mailto:hey@nutrisourceworld.com">hey@nutrisourceworld.com</a></p>
                    </div>
                </section>
                <section>
                    <p>This document was last updated on : <span>20-Oct-2022</span></p>
                </section>
                <footer >
                    <a href="https://farmsanta.com" target="_blank" >Farmsanta</a>
                    <p>Nutrisource Pte Ltd. &copy; All rights are reserved 2022</p>
                </footer>
        </div>
    </div>
  )
}

export default Terms